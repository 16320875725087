import React, { useState, useEffect } from "react";
import Router from "next/router";
import isBefore from "date-fns/isBefore";
import { useCookies } from "react-cookie";

import LoadingSpinner from "@gcp-artifact/compass/lib/components/updated/loadingSpinner";
import MPStorage from "@/services/LocalStorage";
import { updateStorageCredentials } from "@/utilities/utils";
import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import {
  MPInstanceAds,
  MPInstanceAdsV2,
} from "@/services/api/RequestAxios/RequestAxiosAds";
import { MPInstanceTos } from "@/services/api/RequestAxios/RequestAxiosToS";
import { MPInstanceAuthX360 } from "@/services/api/RequestAxios/RequestAxiosAuthX360";

const addAuthorizationToAPIInstances = () => {
  const { token } = MPStorage.requestCacheAuthenticate();

  MPInstancesHub.setAuthorizationToken(token);
  MPInstanceAds.setAuthorizationToken(token);
  MPInstanceAdsV2.setAuthorizationToken(token);
  MPInstanceTos.setAuthorizationToken(token);
  MPInstanceAuthX360.setAuthorizationToken(token);
};

const AuthenticatedRoute = (
  Component = null,
  options: { pathAfterFailure?: string } = {}
) => {
  const AuthenticatedRoute = (props) => {
    const [loading, setLoading] = useState(true);
    const [, setCookie] = useCookies(["auth_c"]);

    useEffect(() => {
      const authenticate = async () => {
        const params = new URLSearchParams(window.location.search);
        const shopifyShop = params.get("shopifyShop");
        const action = params.get("action");
        const authToken = params.get("token");
        const error = params.get("error");

        if (authToken) {
          const credentials = JSON.parse(authToken);
          updateStorageCredentials(credentials, setCookie);

          addAuthorizationToAPIInstances();
        }

        if (shopifyShop) {
          MPStorage.saveToLocalStorage("shopifyShop", shopifyShop);
        }

        if (action) {
          MPStorage.saveToLocalStorage("shopifyAction", action);
        }

        const { token, tokenExpiresAt } = MPStorage.requestCacheAuthenticate();
        const isTokenExpired =
          isBefore(new Date(tokenExpiresAt), new Date()) ?? true;

        if (token && !isTokenExpired) {
          addAuthorizationToAPIInstances();
        }

        if (!!token && !isTokenExpired) {
          setLoading(false);
        } else {
          const view = params.get("view");

          let openModal = "";
          if (view !== undefined && view !== null) {
            openModal = `/?view=${view}`;
          }

          if (!openModal && error) {
            // add query params to redirect from "/number" to "/login"
            Router.replace(options.pathAfterFailure || `/login?error=${error}`);
            return;
          }

          if (openModal && error) {
            // add query params to redirect from "/number" to "/login"
            Router.replace(
              options.pathAfterFailure || `/login${openModal}&error=${error}`
            );
            return;
          }

          // add query params to redirect from "/number" to "/login"
          Router.replace(options.pathAfterFailure || `/login${openModal}`);
        }
      };

      authenticate();
    }, []);

    if (loading) {
      return <LoadingSpinner />;
    }

    return <Component {...props} />;
  };

  return AuthenticatedRoute;
};

export default AuthenticatedRoute;

import translate from "@/utilities/i18n/getTranslate";
import { k } from "@/utilities/i18n/index";
import { SingleOption } from "@/components/Select/SelectTypes";
import { UIModelPartnerChannel } from "@/services/api/partnerChannels/partnerChannels.model";
import MPStorage from "@/services/LocalStorage";
import config from "@/config";
import { UIModelLogin } from "@/services/api/auth/auth.model";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { parseISO, subDays } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";
import { NextRouter } from "next/router";
import { jwtDecode } from "jwt-decode";
import { CurrencySymbolMap } from "@/utilities/currencyMap";

export const createSafeIndex = (length: number, index = 999999999) => {
  const div = index % (length || 1);

  return index - div;
};

// https://stackoverflow.com/questions/5999998/check-if-a-variable-is-of-function-type
export const isFunction = (value: any) => {
  return (
    value &&
    (Object.prototype.toString.call(value) === "[object Function]" ||
      "function" === typeof value ||
      value instanceof Function)
  );
};

export const clearPhoneNUmber = (maybePhoneNumber: string) => {
  return maybePhoneNumber
    .replace(/^0+/, "")
    .split(/[ -.\+:;?!~,`"&|()<>{}\[\]\r\n/\\]+/)
    .join("");
};

export const phoneNumberValidator = async (maybePhoneNumber: string) => {
  const { PhoneNumberUtil } = await import("google-libphonenumber");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const cleanedMaybePhoneNumber = `+${clearPhoneNUmber(maybePhoneNumber)}`;
  if (
    !(
      /^(\+521)/.test(cleanedMaybePhoneNumber) ||
      /^(\+571)/.test(cleanedMaybePhoneNumber)
    )
  ) {
    try {
      const number = phoneUtil.parseAndKeepRawInput(cleanedMaybePhoneNumber);
      return phoneUtil.isValidNumber(number);
    } catch (err) {
      return false;
    }
  } else {
    return true;
  }
};

export const getPayloadForPermissions = (
  channels: UIModelPartnerChannel[],
  selectedNumbers: SingleOption[],
  sharedNumbers: SingleOption[],
  partnerId: string
) => {
  const arrayOfSelectedNumbers = selectedNumbers.map((number) => number.value);
  const arrayOfSharedNumbers = sharedNumbers.map((number) => number.value);
  const filteredChannels = channels.filter((channel) => {
    return arrayOfSelectedNumbers.includes(
      channel.channel?.setupInfo?.phoneNumber
    );
  });

  const selectedNumbersArr = selectedNumbers.map((number) => number.value);

  const revokeNumbers = arrayOfSharedNumbers.filter(
    (item) => !selectedNumbersArr.includes(item)
  );

  const revokeChannels = channels.filter((item) =>
    revokeNumbers.includes(item.channel?.setupInfo?.phoneNumber)
  );

  const payloadForPermissions = filteredChannels.map((channel) => {
    return {
      partner_id: partnerId,
      channel_id: channel.channel?.id,
      permission_granted: true,
    };
  });

  revokeChannels.forEach((item) => {
    payloadForPermissions.push({
      partner_id: partnerId,
      channel_id: item.channel?.id,
      permission_granted: false,
    });
  });

  return payloadForPermissions;
};

export const saveLastLocalization = () => {
  if (
    !document.location.pathname.startsWith("/numbers/app/") &&
    !document.location.pathname.includes("/numbers/sso")
  ) {
    MPStorage.saveToLocalStorage(
      MPStorage.customKeys.LAST_LOCALIZTIONS,
      document.location.pathname
    );
  }
};

export const removeLastLocalization = () => {
  MPStorage.removeLastLocalizationFromLocalStorage();
};

export const truncateCharacters = (
  characters: string,
  maxCharLength: number
): string => {
  if (!characters) {
    return "";
  }

  characters = characters.trim();

  return characters.length > maxCharLength
    ? `${characters.substring(0, maxCharLength)}...`
    : characters;
};

export const validateEmail = (value: string) => {
  const expression = /^$|^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  const regex = new RegExp(expression);

  if (!value.match(regex)) {
    return translate(k.CH_emailErrorMessage);
  }

  return true;
};

export const getUnixTimestamp = (date: string | Date) => {
  if (!date) return null;

  return Math.floor(new Date(date).getTime() / 1000);
};

export const getDateInThePast = (
  date: string | Date = new Date(),
  dateOffset = 0
) => {
  const actualDate = new Date(date);
  const day = actualDate.getUTCDate();

  actualDate.setUTCDate(day - dateOffset + 1); // Added +1 to consider today in the calculation
  return actualDate;
};

export const updateStorageCredentials = (
  credentials: UIModelLogin,
  setCookiesHook,
  fbLogin = false
) => {
  if (fbLogin) {
    setCookiesHook("auth_c", JSON.stringify(credentials), {
      domain: config.domain,
      path: "/",
      sameSite: false,
      secure: false,
    });
    MPStorage.saveCacheAuthenticate(credentials);
    return;
  }

  // if we got to this point, then we are handling login with the new auth flow
  // OAuth login
  const { exp } = jwtDecode(credentials.access_token);

  const authCredentials = {
    accessToken: credentials.access_token,
    expiresIn: exp.toString(),
  };
  setCookiesHook("auth_c", JSON.stringify(authCredentials), {
    domain: config.domain,
    path: "/",
    sameSite: false,
    secure: false,
  });
  MPStorage.saveCacheAuthenticate(authCredentials);
};

export const getUnixTimestampOfLastYear = (fromDate: Date) => {
  // Subtract 1 year and add 1 month to get the next month of the previous year
  fromDate.setFullYear(fromDate.getUTCFullYear() - 1);
  fromDate.setMonth(fromDate.getUTCMonth() + 1);

  // Adjust to first day of the month
  fromDate.setDate(1);

  // Remove hours, minutes, seconds and miliseconds
  fromDate.setHours(0, 0, 0, 0);

  // Convert the date to unix timestamp (in seconds)
  return Math.floor(fromDate.getTime() / 1000);
};

export const getQueryParamsStringFromArray = (
  params: string[],
  paramKey: string
): string => {
  const paramsString = params?.reduce((acc, param, index) => {
    return (
      acc + `${paramKey}=${param}${index !== params.length - 1 ? "&" : ""}`
    );
  }, "");
  return paramsString;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    if (!phoneNumber) return "";

    // at this point, we'd assume that all number passed is coming with the country code added already
    const _phoneNumber = String(phoneNumber).includes("+")
      ? String(phoneNumber)
      : `+${phoneNumber}`;

    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(_phoneNumber, "");

    return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
  } catch (error) {
    // at this point there might be an issue with formatting the number
    // we just return original value that was passed
    return phoneNumber;
  }
};

/**
 * @param dateFrom - Date in ISO format `"YYYY-MM-DD"` or `Date`.
 * @param dateTo - Date in ISO format `"YYYY-MM-DD"` or `Date`.
 * @returns An object with two values: `{ from: new Date("1970-01-01T06:00:00.000Z"), to: new Date("1970-01-01T06:00:00.000Z") }`.
 */

export const getDateFromToInUTC = (
  dateFrom: string | Date,
  dateTo: string | Date
) => {
  const timeZone = "UTC";
  const processedDateFrom =
    typeof dateFrom === "string" ? parseISO(dateFrom) : dateFrom;
  const processedDateTo =
    typeof dateTo === "string" ? parseISO(dateTo) : dateTo;

  const from = utcToZonedTime(processedDateFrom, timeZone);
  const to = utcToZonedTime(processedDateTo, timeZone);

  return {
    from,
    to,
  };
};

/**
 * @param date - Date in ISO format `"YYYY-MM-DD"` or `Date`.
 * @returns An ISO format date.
 */

export const getISODate = (date: string | Date) => {
  return typeof date === "string" ? parseISO(date) : date;
};

/**
 * @param date - Date in ISO format `"YYYY-MM-DD"`.
 * @returns A Date Instance `new Date("1970-01-01T06:00:00.000Z")`.
 */

export const getDateInUTC = (date: string) => {
  const dateToString = parseISO(date);
  const dateInUTC = utcToZonedTime(dateToString, "UTC");

  return dateInUTC;
};

/**
 * @param s - The string to capitalize the first letters of, e.g., `"hello, world!"`.
 * @returns The string with its first letters capitalized, e.g., `"Hello, World!"`.
 */

export const capitalizeLetters = (s: string) => {
  return s
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 *
 * @param email - A string value representing the email to be checked
 * @returns A boolean value representing the valid state of the email
 */
export const isValidEmail = (email: string) => {
  const expression = /^$|^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  const regex = new RegExp(expression);

  if (!email || !email.match(regex)) {
    return false;
  }

  return true;
};

/**
 * This is an util function that returns single entry of all query params in a url.
 * If a particular params appears multiple times, only the first one is considered
 * @param router - Router handler of choice
 * @returns either an object representing the available query params or undefined for non-existing query params
 */
export const getURLQueryParams = (router: NextRouter) => {
  if (!router.isReady) return;

  const asPath = router.asPath;
  const queries = asPath.split("?")?.[1]?.split("&");

  // return object representation of the queries array
  return queries?.reduce((acc, cur) => {
    const query = cur.split("=");
    !acc[query[0]] && (acc[query[0]] = query[1]);
    return acc;
  }, {});
};

export const formatNumber = (
  number: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
  roundingMode = "ceil"
) => {
  const num = isNaN(number) || number === Infinity ? 0 : number;

  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits,
    minimumFractionDigits,
    roundingMode,
  } as Intl.NumberFormatOptions).format(num);
};

export const TIME_RANGES = {
  CUSTOM: "custom",
  DAY: "day",
  WEEK: "week",
  BI_WEEK: "bi-week",
  MONTH: "month",
};

export const timeOptions = [
  {
    label: translate(k.INS_custom),
    value: TIME_RANGES.CUSTOM,
  },
  {
    label: translate(k.INS_yesterday),
    value: TIME_RANGES.DAY,
    numberOfDays: 1,
  },
  {
    label: translate(k.INS_lastWeek),
    value: TIME_RANGES.WEEK,
    numberOfDays: 7,
  },
  {
    label: translate(k.INS_last2Weeks),
    value: TIME_RANGES.BI_WEEK,
    numberOfDays: 14,
  },
  {
    label: translate(k.INS_lastMonth),
    value: TIME_RANGES.MONTH,
    numberOfDays: 30,
  },
];

export const getDateRanges = (timeRange) => {
  if (
    timeRange.dateTo &&
    timeRange.dateFrom &&
    timeRange.value === TIME_RANGES.CUSTOM
  ) {
    const dateFromUTC = getISODate(timeRange.dateFrom);
    const dateToUTC = getISODate(timeRange.dateTo);

    return {
      dateFrom: format(dateFromUTC, "yyyy-MM-dd"),
      dateTo: format(dateToUTC, "yyyy-MM-dd"),
    };
  } else {
    const todayUTC = new Date(new Date().toISOString());
    const dateTo = subDays(todayUTC, 1);
    const dateFrom = subDays(dateTo, timeRange.numberOfDays - 1);

    return {
      dateFrom: format(dateFrom, "yyyy-MM-dd"),
      dateTo: format(dateTo, "yyyy-MM-dd"),
    };
  }
};

export const getCurrencySymbol = (currency) => {
  const currencySymbol = currency
    ? CurrencySymbolMap[currency.toUpperCase()]
    : "$";
  return currencySymbol;
};

export const checkOutgoing = (data) => {
  const isOutgoingByDirection = data.direction === "outgoing";
  const nameOrMessage = data?.name || data?.message || "";
  const suffixes = [" (button)", " (button_reply)", " (list)", " (list_reply)"];

  const suffixFound = suffixes.find((suffix) => nameOrMessage.endsWith(suffix));

  if (suffixFound || isOutgoingByDirection) {
    return true;
  }

  return false;
};

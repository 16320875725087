import * as React from "react";
import AuthenticatedRoute from "@/utilities/hoc/useAuthenticatedRoute";
import { useRouter } from "next/router";
import MPStorage from "@/services/LocalStorage";

const Home = () => {
  const router = useRouter();

  React.useEffect(() => {
    const shopifyAction = MPStorage.getFromLocalStorage("shopifyAction");
    if (shopifyAction) {
      router.push("/integrations");
    } else {
      router.push("/copilot");
    }
  }, []);

  return <></>;
};

export default AuthenticatedRoute(Home);
